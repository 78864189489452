<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set highlighted>
						<v-layout wrap>
							<!-- IDREVISION -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idrevision-mimdgrevision`"
									:label="$t('mimdgrevision.idrevision')"
									v-model="model.idrevision"
									disabled
									toplabel
									maxlength="10"
								>
								</pui-text-field>
							</v-flex>
							<!-- VERSION -->
							<v-flex xs12 md3>
								<pui-text-field
									:id="`version-mimdgrevision`"
									:label="$t(`mimdgrevision.version`)"
									itemValue="version"
									v-model="model.version"
									required
									toplabel
									maxlength="10"
								>
								</pui-text-field>
							</v-flex>
							<!-- NAME -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`name-mimdgrevision`"
									v-model="model.name"
									:label="$t('mimdgrevision.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
							<!-- FECBAJA -->
							<v-flex xs12 md2>
								<pui-date-field
								:id="`fecbaja-mrescategoriacondicion`"
								:label="$t(`mrescategoriacondicion.fecbaja`)"
								v-model="model.fecbaja"
								toplabel
								></pui-date-field>
							</v-flex>
							<!-- MINI AUDIT -->
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mimdg-revision-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'mimdgrevision',
			refreshSelect: 0
		};
	},
};
</script>
